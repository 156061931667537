import { Injectable, inject, signal } from '@angular/core';
import { OidcService } from './oidc.service';
import { ServerConfig } from './server.config';
import { cloneDeep } from 'lodash';
import { LocalStorageService } from './localstorage.service';
import { BroadcastService } from './broadcast.service';

export interface Organisation {
    id: string;
    name: string;
    urls: { api: string, lapi: string, ldb: string, risrai: string };
    cluster: string;
}

export interface OrganisationsResponse {
    ok: boolean;
    orgs: Organisation[];
    clusters: { id: string, status: 'ok' | 'error' }[];
}

@Injectable({
    providedIn: 'root'
})
export class ClusterService {

    private organisations: {[key:string]: Organisation} = {};

    private oidc = inject(OidcService);
    private serverConfig = inject(ServerConfig);
    private localStorage = inject(LocalStorageService);
    private broadcast = inject(BroadcastService);

    private organisationsLoaded = signal<boolean>(false);

    getOrganisation(orgId: string): Organisation {
        if (!this.organisationsLoaded()) {
            throw new Error('Organisations not loaded');
        }
        const org = this.organisations[orgId];
        if (org === undefined) {
            throw new Error('Organisation not found');
        }
        return org;
    }

    getUrls(orgId: string) {
        return this.getOrganisation(orgId).urls;
    }

    getCurrentOrganisation() {
        const org = this.oidc.organisation;
        return this.getOrganisation(org);
    }

    getCurrentUrls() {
        return this.getCurrentOrganisation().urls;
    }

    getOrganisations() {
        return cloneDeep(this.organisations);
    }

    getOrganisationsCount() {
        return Object.keys(this.organisations).length;
    }

    private getProfileOrgKey() {
        const username = this.oidc.currentUser();
        return `${username}_profile_organisations`;
    }

    loadProfileOrganisations(): Promise<void> {
        return this.getProfileOrganisations().then(response => {
            const key = this.getProfileOrgKey();
            let orgs;
            if (response.ok) {
                this.localStorage.setItem(key, response.orgs);
                orgs = response.orgs;
            } else {
                try {
                    orgs = this.localStorage.getItem(key);
                } catch (e) {
                    console.error(`ClusterService: Error getting value ${key}`);
                    orgs = response.orgs;
                }
            }
            return orgs;
        }).catch(err => {
            const key = this.getProfileOrgKey();
            console.error(`ClusterService: Error getting value ${key}. Trying from cache`, err);
            let orgs: Organisation[];
            try {
                orgs = this.localStorage.getItem(key);
            } catch (e) {
                console.error(`ClusterService: Error getting value ${key}`, e);
                orgs = [];
            }
            return orgs || [];
        }).then(orgs => {
            const orgsMap = {};
            orgs.forEach(org => {
                orgsMap[org.id] = org;
            });
            this.organisations = orgsMap;
            this.organisationsLoaded.set(true);
            this.broadcast.broadcast('OrganisationProfilesLoaded');
        })
    }

    getProfileOrganisations(): Promise<OrganisationsResponse> {
        return this.callApi<OrganisationsResponse>('GET', '/my_organisations');
    }

    async getAllOrganisations(): Promise<Organisation[]> {
        const response = await this.callApi<OrganisationsResponse>('GET', '/all_organisations');
        return response.orgs;
    }

    ensureOrganisation() {
        this.getCurrentOrganisation();
        return Promise.resolve();
    }

    private async callApi<T>(method: string, path: string, data?: any) {
        const url = this.serverConfig.config.urls.org.trimEnd('/') + path;
        const apiInfo = await this.oidc.apiInfo();

        const response = await fetch(url, {
            method: method,
            headers: {
                'Authorization': apiInfo.token,
                'EAS-Organisation': apiInfo.organisation || '',
                'EAS-Username': apiInfo.username || '',
            },
            body: data
        })

        const json = await response.json();
        return json as T;
    };

}