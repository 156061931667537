(function() {
  'use strict';

  function SetupService($q, $location, Profile, Network, CheckService, Updator, Auth, Database,
                        LocalStore, Cache, LocalUsers, LocalizationService, kzLocalStorage,
                        ClusterService, AsyncTasks, SidebarService, FeaturesService, DB_ERRORS) {
    var service = {};
    var deltaClean = 30 * 24 * 60 * 60 * 1000;

    var loadUserProfile = function() {
      return Profile.find()
        .catch(function(err) {
          // If we got forbidden, lets reset organisation
          if (err && err.status === 403) {
            Auth.unsetOrganisation();
            return Database._hasOrganisationSet();
          }

          // If we cannot get it we are either mismatched or
          // we are offline
          if (err && err.status === 404) {
            if (Network.isOffline()) {
              return $q.reject(DB_ERRORS.offlineNotAllowed);
            }

            return $q.reject({ status: 404, message: 'Could not load current user' });
          }

          return $q.reject(err);
        });
    };

    var loadOrganisation = function() {
      return Profile.findOrganisation()
        .then(function(org) {
          LocalizationService.loadDateTimeFormats(org);
        })
        .catch(function(err) {
          console.log('Failed fetch profile\'s organisation', err);
          return $q.reject(DB_ERRORS.organisationNotSet);
        });
    };

    var handleOrganisation = function() {
      return ClusterService.loadProfileOrganisations()
        // Attempt to set organisation if possible
        .then(function() {
          var orgs = ClusterService.getOrganisations();
          var orgCount = ClusterService.getOrganisationsCount();
          if (orgCount === 0) {
            console.log('Setup: No organisations found');
            return $q.reject({ status: 0, message: 'No organisations loaded' });
          }

          var curOrg = Auth.currentOrganisation();
          if (!curOrg) {
            console.log('Setup: Organisation not set');
            if (orgCount === 1) {
              console.log('Setup: There is only one org, setting it');
              Auth.setOrganisation(_.keys(orgs)[0]);
            }
          } else if (orgs[curOrg] === undefined) {
            console.log('Setup: Organisation set but invalid');
            if (orgCount === 1) {
              console.log('Setup: There is only one org, setting it');
              Auth.setOrganisation(_.keys(orgs)[0]);
            } else {
              Auth.setOrganisation(null);
            }
          }
          SidebarService.registerAll();
        })

        // Handle redirect if organisation not set
        .then(Database._hasOrganisationSet)

        // Double check organisation is selected and exists
        .then(_ => ClusterService.ensureOrganisation());
    };

    service.checkStorageMode = function() {
      return Cache.cachedPromise(Database._checkStorageMode, { cached: true });
    };

    /**
     * Sanity checks and set up to make sure everything is loaded properly
     * @return {object} Promise
     */
    service.initApp = function() {
      if (service.initAppRun) {
        return $q.when();
      }
      service.initAppRun = true;
      return $q.when();
    };

    service.finalise = function() {
      Updator.startUpdator();
      AsyncTasks.startUpdator();
    };

    service.setupApp = function() {
      // if (service.setupAppRunning) {
      //   return $q.when();
      // }

      service.setupAppRunning = true;
      return $q.when()
        .then(service.checkStorageMode)
        .then(CheckService.checkConnectivity)
        .then(_ => Auth.ensureLoggedIn())
        .then(_ => Auth.isLoggedIn())
        // .then(Database._setActualMode)
        .then(Database._isOfflineAllowed)
        .then(Database._checkCredsInQuery)
        .then(handleOrganisation)
        .then(CheckService.checkConnectivity)
        .then(CheckService.checkVersion)
        .then(service.cleanUpCache)
        .then(loadUserProfile)
        .then(loadOrganisation)
        .then(Database.finalize)
        .then(Database._setStarted)
        .then(_ => FeaturesService.ensureFeatures())
        .then(service.finalise)
        .finally(function() {
          service.setupAppRunning = false;
        });
    };

    service.setupAppChooser = function() {
      return CheckService.checkConnectivity()
        .then(service.checkStorageMode)
        .then(_ => Auth.isLoggedIn())
        .then(Database._setActualMode)
        .then(Database._isOfflineAllowed)
        .then(Database._checkCredsInQuery)
        .then(ClusterService.loadProfileOrganisations.bind(ClusterService))
        .then(Database._setStarted);
    };

    service.setupInitialSync = function() {
      return CheckService.checkConnectivity()
        .then(service.checkStorageMode)
        .then(_ => Auth.isLoggedIn())
        .then(Database._setActualMode)
        .then(Database._isOfflineAllowed)
        .then(Database._hasPrivateDatabase)
        .then(Database._setStarted)
        .catch(function(error) {
          // Catch all but missing on the backend
          if (error.status === 404) {
            return $q.reject(error);
          }
        });
    };

    service.setupRegistering = function() {
      return Database._setActualMode()
        .then(service.checkStorageMode)
        .then(Database._isOfflineAllowed)
        .then(Database._setStarted);
    };

    service.setupLogin = function() {
      Network.resetMode();
      return CheckService.checkConnectivity()
        .then(service.checkStorageMode)
        .then(Database._setActualMode)
        .then(Database._isOfflineAllowed)
        .then(Database._checkCredsInQuery)
        .then(function() {
          return Auth.isLoggedIn()
            .then(function() {
              var cameFrom = $location.search().came_from || '/';
              return $q.reject({ status: 302, url: cameFrom });
            })
            .catch(function(err) {
              if (err && err.status === 302) {
                return $q.reject(err);
              }
            });
        })
        .then(Database._setStarted);
    };

    service.cleanUpData = function(options) {
      options = options || {};
      var websqlName = '_pouch__websqldb__pouch_check';
      window.localStorage.removeItem(websqlName);
      var proms = [
        Cache.removeLocalData()
      ];

      if (!options.onlyCache) {
        proms.push(LocalStore.removeData());
        proms.push(LocalUsers.removeDb());
      }

      return $q.all(proms);
    };

    service.cleanUpCache = function() {
      var lsKey = Auth.currentOrganisation() + ':' + Auth.currentUser() + ':kzCleanUp';

      if (service.calledCleanUp === lsKey) {
        return $q.when();
      }

      if (Network.isOffline()) {
        return $q.when();
      }

      // console.log('Setup: checking clean up');
      service.calledCleanUp = lsKey;

      var cleanup = kzLocalStorage.getItem(lsKey);
      if (cleanup.date) {
        var now = new Date();
        var dt = new Date(cleanup.date);
        if (now - dt < deltaClean) {
          console.log(
            'Setup: Not running clean up. Next clean scheduled for ',
            new Date(dt.getTime() + deltaClean)
          );
          return $q.when();
        }
      }

      console.log('Setup: Starting clean up');
      return Cache.removeLocalData()
        .then(function() {
          console.log('Setup: Finished clean up');
          kzLocalStorage.setItem(lsKey, { date: new Date() });
          // window.location.reload();
        });
    };

    service.forceSetup = function() {
      if (!Database._started) {
        return $q.reject({ status: 444, message: 'Not setup yet' });
      }

      return $q.when();
    };

    service.logout = function(options) {
      return Database.cancel()
        .then(function() {
          return Auth.logout(options);
        });
    };

    return service;
  }

  SetupService.$inject = ['$q', '$location', 'ProfileService', 'NetworkService', 'CheckService',
    'UpdatorService', 'AuthService', 'DatabaseService', 'LocalStoreService', 'CacheService',
    'LocalUsersService', 'LocalizationService', 'kzLocalStorage', 'ClusterService',
    'AsyncTasksService', 'SidebarService', 'FeaturesService', 'DB_ERRORS'];

  angular.module('blocks.setup')
    .service('SetupService', SetupService);
})();
